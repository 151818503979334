import styles from '../registerPage.module.css';

function SignUpSuccessful() {
    return (
        <div className={styles.successBox}>
            You Signed Up succesfully
        </div>
    )
}

export default SignUpSuccessful