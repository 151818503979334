import { useEffect, useState } from "react";
import { useAuthContext } from "../../shared/authProvider";
import styles from "../summaryPage.module.css";

function GreetingMessage() {
    const  { user } = useAuthContext();
    const [greeting, setGreeting] = useState<string>('')


    function getGreetingMessage() {
        let currentHour = new Date().getHours();
        if (currentHour >= 0 && currentHour <= 11) {
            setGreeting('Good Morning');
        } else if (currentHour > 11 && currentHour < 14) {
            setGreeting('Good Day');
        } else if (currentHour >= 14 && currentHour < 18) {
            setGreeting('Good afternoon');
        } else {
            setGreeting('Good Evening');
        }
    }

    useEffect(() => {
        getGreetingMessage()
    }, [])
    return ( 
        <div className={styles.greetingMessage}>
            <div className={styles.greetingBox}>
                        <h2>{greeting}</h2>
                        <span>{user?.displayName}</span>
                    </div>
        </div>
     );
}

export default GreetingMessage;